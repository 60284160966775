<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        addNew: true,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Preview', value: 'image', component: { name: 'dt-img' } },
        ],
        action: {
          hideView: true,
          child: 'component',
          showChildList: true,
        },
      },
    };
  },
};
</script>
